import {createTheme, Theme} from "@mui/material";
import {orange} from "@mui/material/colors";

const theme: Theme = createTheme({
    palette: {
        mode: 'light', // Specifying light mode for consistency
        primary: {
            main: '#7AC27C', // Light green primary color
            light: '#C0D9B9', // Lighter variation for hover/focus states
            dark: '#55AA55', // Darker variation for text and subtle accents
        },
        secondary: {
            main: '#E2F3E8', // Light complementary color
        },
        background: {
            default: '#FFFFFF', // White background
            paper: '#F5F5F5', // Slightly off-white for cards and surfaces
        },
        text: {
            primary: '#212121', // Dark text for contrast
            secondary: '#757575', // Lighter text for subtle elements
        },
        // Define other colors as needed (e.g., error, success, warning)
    },
    typography: {
        fontFamily: 'Poppins, sans-serif', // Set Poppins as the base font
        fontSize: 14, // Base font size
        h1: {
            fontSize: 48, // Define specific headings
            fontWeight: 700,
        },
        h2: {
            fontSize: 36,
            fontWeight: 700,
        },
        h3: {
            fontSize: 24,
            fontWeight: 600,
        },
        body1: {
            lineHeight: 1.5, // Adjust line height for improved readability
        },
        button: {
            fontFamily: 'Poppins, sans-serif', // Ensure buttons inherit Poppins font
            fontWeight: 500,
        },
    },
});

export default theme;