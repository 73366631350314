import "../App.css"
import {AppBar, Toolbar, Typography} from "@mui/material";
import theme from "../theme";

function NavigationBar() {
    return (
        <AppBar
            position="absolute"
            sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                display: 'flex',
            }}
        >
            <Toolbar sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h1" color={theme.palette.primary.main}>inventastics</Typography>
            </Toolbar>
        </AppBar>
    );
}

export default NavigationBar;