import React from 'react';
import {CssBaseline, ThemeProvider} from "@mui/material";
import NavigationBar from "./components/NavigationBar";
import theme from "./theme";
import Hero from "./components/Hero";
import PreregisterForm from "./components/PreregisterForm";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <NavigationBar/>
            <Hero />
            <PreregisterForm />
        </ThemeProvider>
    );
}

export default App;
