import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const PreregisterForm = () => {
    return (
        <Box
            id={"preregister"}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                overflow: 'auto',
                p: 4, // Standard padding around the content
            }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{mb: 2}}>
                        Form Title
                    </Typography>
                    <Typography variant="body1">
                        Form description or instructions
                    </Typography>
                </Grid>
                {/* Stacked form inputs */}
                <Grid item xs={12}>
                    <TextField label="Name" fullWidth required/>
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Email Address" type="email" fullWidth required/>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PreregisterForm;