import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Card, Fab, Paper} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // Import the icon

const Hero = () => {
    const heroImage: string = '/11065.jpg'; // Replace with your image path

    const handleScrollToNext = () => {
        const nextSection = document.querySelector('#preregister'); // Replace with your selector
        if (nextSection) {
            nextSection.scrollIntoView({behavior: 'smooth'});
        }
    };


    return (
        <Box
            sx={{
                display: 'flex',
                overflow: 'hidden', // Allow scrolling below
                position: 'relative', // Important for image positioning
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover', // Adjust size as needed
                backgroundPosition: 'center', // Adjust position as needed
                height: '100vh', // Full screen height
            }}
        >
            <Grid container justifyContent="space-between">
                <Grid item xs={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Card
                            sx={{
                                p: 4,
                                backgroundColor: "#FFFFFF"
                            }}
                        >
                            <Typography variant="h1" sx={{mb: 2}}>
                                Your hero title
                            </Typography>
                            <Typography variant="body1">
                                Your descriptive text for the hero section.
                            </Typography>
                        </Card>
                    </Box>
                </Grid>
            </Grid>

            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0, // Place at the bottom
                    padding: 4,
                    left: '50%', // Horizontally center
                    transform: 'translateX(-50%)', // Adjust for centering
                }}
            >
                <Fab
                    color={"primary"}
                    onClick={handleScrollToNext}
                >
                    <ArrowDownwardIcon
                        sx={{color: 'white', fontSize: 30}}
                    />
                </Fab>
            </Box>
        </Box>
    );
};

export default Hero;